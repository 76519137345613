import { AiFillCheckCircle, AiOutlineRollback } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';

import { TbReportAnalytics, TbFileInvoice } from 'react-icons/tb';
import { BsCash } from 'react-icons/bs';

import {
  BACK_TO_PMS,
  CASH_SALES_FOR_APPROVAL,
  CASH_SALES_REPORT,
  CASH_SALES_UPLOAD,
  INVOICE_FOR_APPROVAL,
  INVOICE_REPORT,
  INVOICE_UPLOAD,
} from '../routers/RouteConstants';

// const reportSubMenuNavigation = {
//   id: '1',
//   title: 'Reports',
//   icon: <TbReportAnalytics className="me-2" size={20} />,
//   items: [
//     {
//       title: 'Cash Sales',
//       icon: <BsCash className="me-2" size={20} />,
//       path: CASH_SALES_REPORT,
//     },
//     {
//       title: 'Invoice',
//       icon: <TbFileInvoice className="me-2" size={20} />,
//       path: INVOICE_REPORT,
//     },
//   ],
// };

const forApprovalSubMenuNavigation = {
  id: '1',
  title: 'For Approval',
  icon: <AiFillCheckCircle className="me-2" size={20} />,
  items: [
    {
      title: 'Cash Sales',
      icon: <BsCash className="me-2" size={20} />,
      path: CASH_SALES_FOR_APPROVAL,
    },
    {
      title: 'Invoice',
      icon: <TbFileInvoice className="me-2" size={20} />,
      path: INVOICE_FOR_APPROVAL,
    },
  ],
};

const uploadToNetSuiteSubMenuNavigation = {
  id: '2',
  title: 'Upload To NetSuite',
  icon: <FaUpload className="me-2" size={20} />,
  items: [
    {
      title: 'Cash Sales',
      icon: <BsCash className="me-2" size={20} />,
      path: CASH_SALES_UPLOAD,
    },
    {
      title: 'Invoice',
      icon: <TbFileInvoice className="me-2" size={20} />,
      path: INVOICE_UPLOAD,
    },
  ],
};

const backToPMSNavigation = {
  title: 'Back to PMS',
  icon: <AiOutlineRollback className="me-2" size={20} />,
  path: BACK_TO_PMS,
};

export {
  // reportSubMenuNavigation,
  forApprovalSubMenuNavigation,
  uploadToNetSuiteSubMenuNavigation,
  backToPMSNavigation,
};
