import axios from 'axios';
import { API_CALL_ERROR, GET_BUILDINGS_ENDPOINT } from './_constants';

export async function getBuildings() {
  try {
    const { data } = await axios.get(GET_BUILDINGS_ENDPOINT);
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
