export const cashSaleTableHeader = [
  {
    title: 'Invoice ID',
    dataIndex: 'invoiceId',
    key: 'invoiceId',
    sorter: (a, b) => a.invoiceId - b.invoiceId,
    fixed: 'left',
  },
  {
    title: 'Invoice Building',
    dataIndex: 'invBuilding',
    key: 'invBuilding',
  },
  {
    title: 'External ID',
    dataIndex: 'ExternalID',
    key: 'ExternalID',
    sorter: (a, b) => a.ExternalID.localeCompare(b.ExternalID),
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    sorter: (a, b) => a.customer.localeCompare(b.customer),
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    key: 'memo',
    sorter: (a, b) => a.memo.localeCompare(b.memo),
  },
  {
    title: 'Sales Rep',
    dataIndex: 'salesRep',
    key: 'salesRep',
    sorter: (a, b) => a.salesRep.localeCompare(b.salesRep),
  },
  {
    title: 'Subsidiary',
    dataIndex: 'subsidiary',
    key: 'subsidiary',
    sorter: (a, b) => a.subsidiary.localeCompare(b.subsidiary),
  },
  {
    title: 'Department',
    dataIndex: 'department',
    key: 'department',
    sorter: (a, b) => a.department.localeCompare(b.department),
  },
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'class',
    sorter: (a, b) => a.class.localeCompare(b.class),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    sorter: (a, b) => a.location.localeCompare(b.location),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Exchange Rate',
    dataIndex: 'eRate',
    key: 'eRate',
    sorter: (a, b) => a.eRate - b.eRate,
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    sorter: (a, b) => a.item.localeCompare(b.item),
  },
  {
    title: 'Item: Description',
    dataIndex: 'itemDesc',
    key: 'itemDesc',
    sorter: (a, b) => a.itemDesc.localeCompare(b.itemDesc),
  },
  {
    title: 'Item: Rate',
    dataIndex: 'itemRate',
    key: 'itemRate',
    sorter: (a, b) => a.itemRate - b.itemRate,
  },
  {
    title: 'Item: Amount',
    dataIndex: 'itemAmount',
    key: 'itemAmount',
    sorter: (a, b) => a.itemAmount - b.itemAmount,
  },
  {
    title: 'Item: Tax Code',
    dataIndex: 'itemTaxCode',
    key: 'itemTaxCode',
    sorter: (a, b) => a.itemTaxCode.localeCompare(b.itemTaxCode),
  },
  {
    title: 'Item: Tax Amount',
    dataIndex: 'itemTaxAmount',
    key: 'itemTaxAmount',
    sorter: (a, b) => a.itemTaxAmount - b.itemTaxAmount,
  },
  {
    title: 'Item: Gross Amount',
    dataIndex: 'itemGrossAmount',
    key: 'itemGrossAmount',
    sorter: (a, b) => a.itemGrossAmount - b.itemGrossAmount,
  },
  {
    title: 'Item: Department',
    dataIndex: 'itemDept',
    key: 'itemDept',
    sorter: (a, b) => a.itemDept.localeCompare(b.itemDept),
  },
  {
    title: 'Item: Class',
    dataIndex: 'itemClass',
    key: 'itemClass',
    sorter: (a, b) => a.itemClass.localeCompare(b.itemClass),
  },
  {
    title: 'Item: Location',
    dataIndex: 'itemLocation',
    key: 'itemLocation',
    sorter: (a, b) => a.itemLocation.localeCompare(b.itemLocation),
  },
  {
    title: 'Item: Apply Witdholding Tax',
    dataIndex: 'itemApplyWHtax',
    key: 'itemApplyWHtax',
    sorter: (a, b) => a.itemApplyWHtax - b.itemApplyWHtax,
  },
  {
    title: 'Item: Witdholding Tax Code',
    dataIndex: 'itemApplyWHtaxCode',
    key: 'itemApplyWHtaxCode',
    sorter: (a, b) => a.itemApplyWHtaxCode - b.itemApplyWHtaxCode,
  },
  {
    title: 'Item: Witdholding Tax Rate',
    dataIndex: 'itemWHTaxRate',
    key: 'itemWHTaxRate',
  },
  {
    title: 'Item: Witdholding Tax Base Amount',
    dataIndex: 'itemWHTaxBaseAmt',
    key: 'itemWHTaxBaseAmt',
    sorter: (a, b) => a.itemWHTaxBaseAmt - b.itemWHTaxBaseAmt,
  },
  {
    title: 'Item: Witdholding Tax Amount',
    dataIndex: 'itemWHTaxAmount',
    key: 'itemWHTaxAmount',
    sorter: (a, b) => a.itemWHTaxAmount - b.itemWHTaxAmount,
  },
  {
    title: 'Undeposited Funds',
    dataIndex: 'undepositedFunds',
    key: 'undepositedFunds',
    sorter: (a, b) => a.undepositedFunds.localeCompare(b.undepositedFunds),
  },
];
