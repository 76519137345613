import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  CASH_SALES_FOR_APPROVAL,
  HOME_ROUTE,
  INVOICE_FOR_APPROVAL,
  INVOICE_UPLOAD,
} from './RouteConstants';
import { CashSales, Dashboard, Invoice } from '../../pages';
import { Navigation } from '../../components';
import CashSalesForApproval from '../../pages/ForApproval/CashSales/CashSalesForApproval';
import CashSalesUploading from '../../pages/Uploading/CashSalesUploading';
import { CASH_SALES_UPLOAD } from './RouteConstants';
import InvoiceForApproval from '../../pages/ForApproval/Invoice/InvoiceForApproval';
import InvoiceUploading from '../../pages/Uploading/InvoiceUploading';
export default function MainRoute() {
  return (
    <Navigation>
      <Routes>
        <Route path={HOME_ROUTE} exact={true} element={<Dashboard />} />
        <Route
          path={CASH_SALES_FOR_APPROVAL}
          element={<CashSalesForApproval />}
        />
        <Route path={CASH_SALES_UPLOAD} element={<CashSalesUploading />} />

        <Route path={INVOICE_FOR_APPROVAL} element={<InvoiceForApproval />} />

        <Route path={INVOICE_UPLOAD} element={<InvoiceUploading />} />
        <Route path="*" element={<Navigate to={HOME_ROUTE} />} />
      </Routes>
    </Navigation>
  );
}
