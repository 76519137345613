import React from 'react';
import { Form } from 'react-bootstrap';

export default function Selection({
  title,
  value,
  onChange,
  options,
  disabled,
}) {
  return (
    <Form.Group className="mb-3">
      <Form.Select value={value} onChange={onChange} disabled={disabled}>
        <option value="">Select {title}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
