import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login/Login';
import { HOME_ROUTE } from './RouteConstants';

export default function AuthRoute() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} exact={true} element={<Login />} />
      <Route path="*" element={<Navigate to={HOME_ROUTE} />} />
    </Routes>
  );
}
