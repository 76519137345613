import axios from 'axios';
import { API_CALL_ERROR, LOGIN_ENDPOINT } from './_constants';

export async function login(username, password) {
  const qs = require('qs');
  try {
    const { data } = await axios.post(
      LOGIN_ENDPOINT,
      qs.stringify({
        username: username,
        password: password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
