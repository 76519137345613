const BASE_URL_ENDPOINT = 'https://netsuite.mytownph.com';

const CASH_SALES_ENDPOINT = '/getCashSales';
const SAVE_CASH_SALES = '/saveCashSales';
const GET_APPROVED_CASH_SALES = '/postNetSuiteCashSales';
const SAVE_UPLOADED_CASH_SALES = '/savePostedCashSales';

const INVOICE_ENDPOINT = '/getinvoice';
const SAVE_INVOICE = '/saveInvoice';
const GET_APPROVED_INVOICES = '/postNetSuiteInvoice';
const SAVE_UPLOADED_INVOICES = '/savePostedInvoice';

const BUILDING_ENDPOINT = '/bcodes';
const LOGIN = '/login';

export const GET_CASH_SALES_ENDPOINT = BASE_URL_ENDPOINT + CASH_SALES_ENDPOINT;
export const SAVE_CASH_SALES_ENDPOINT = BASE_URL_ENDPOINT + SAVE_CASH_SALES;
export const GET_APPROVED_CASH_SALES_ENDPOINT =
  BASE_URL_ENDPOINT + GET_APPROVED_CASH_SALES;
export const SAVE_UPLOADED_CASH_SALES_ENDPOINT =
  BASE_URL_ENDPOINT + SAVE_UPLOADED_CASH_SALES;

export const GET_INVOICES_ENDPOINT = BASE_URL_ENDPOINT + INVOICE_ENDPOINT;
export const SAVE_INVOICES_ENDPOINT = BASE_URL_ENDPOINT + SAVE_INVOICE;
export const GET_APPROVED_INVOICES_ENDPOINT =
  BASE_URL_ENDPOINT + GET_APPROVED_INVOICES;
export const SAVE_UPLOADED_INVOICES_ENDPOINT =
  BASE_URL_ENDPOINT + SAVE_UPLOADED_INVOICES;

export const GET_BUILDINGS_ENDPOINT = BASE_URL_ENDPOINT + BUILDING_ENDPOINT;
export const LOGIN_ENDPOINT = BASE_URL_ENDPOINT + LOGIN;

// API RESPONSE
export const API_CALL_ERROR = 'api_call_error';
